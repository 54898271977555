import React from 'react'

import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import MainLayout from "../layout/MainLayout";
// import HomePage from "../pages/home";
// import AboutPage from "../pages/about";
// import NFTPage from "../pages/nft";
// import TeamPage from "../pages/team";
const HomePage = React.lazy(() => import("../pages/home"));
const AboutPage = React.lazy(() => import("../pages/about"));
const NFTPage = React.lazy(() => import("../pages/nft"));
const TeamPage = React.lazy(() => import("../pages/team"));


const AppRoutes = () => {
  return (
    <MainLayout>
      <Routes>
        <Route path="/" element={<React.Suspense fallback={<>...</>}>
          <HomePage />
        </React.Suspense>} />
        <Route path="/about" element={<React.Suspense fallback={<>...</>}>
          <AboutPage />
        </React.Suspense>} />
        <Route path="/nfts" element={<React.Suspense fallback={<>...</>}>
          <NFTPage />
        </React.Suspense>} />
        {/*<Route path="/team" element={<React.Suspense fallback={<>...</>}>
          <TeamPage />
        </React.Suspense>} />*/}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </MainLayout>
  )
}

export default AppRoutes;