import Container from "../../components/Container";
import Flex from "../../components/Flex";
import styled from 'styled-components/macro';
import Button from "../../components/Button";
import MenuGroup from "../../components/MenuGroup";
import "../header/style.css";
import { useNavigate } from "react-router-dom";
import React from 'react';
import Modal from 'react-modal';

const HeaderWrapper = styled.div`
  position: absolute;
  width: 100%;
`

// const InnerWrapper = styled.div`
//   margin: 0;
//   padding: 0 10px;
// `

const Logo = styled.div`
  z-index: 30;
  a {
    display: inline-block;
    width: 200px;
    height: 110px;
    background: transparent;
    background-image: url("https://d1estwaobnn6ty.cloudfront.net/web/public/images/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    cursor:pointer;
  }
`


const EARNBTN = styled(Button)`
  
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  z-index: 10;
`

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const Header = () => {
  // const MenuData = [
  //   'HOME',
  //   'ABOUT',
  //   'NFTS',
  //   'TEAM'
  // ];
  let navigate = useNavigate();

  const handlechange = () => {
    navigate("/", { replace: true });
  }

  //let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() { setIsOpen(true); }
  function closeModal() { setIsOpen(false); }

  return (
    <HeaderWrapper>
      <Container className="men-logo">
        <Flex noWrap alignCenter justifyBetween gap='0'>
          <Logo className='logo-header'>
            <a imgurl='/images/logo.png' onClick={handlechange} />
          </Logo>

          <ButtonGroup>
            <EARNBTN className="btn-white header-button"><a target='_blank' className="title-main" href="/CHLI_WHITEPAPER.pdf">WHITE PAPER</a></EARNBTN>
            <EARNBTN onClick={openModal} className="header-button"><a className="title-main" href="#">PLAY TO EARN</a></EARNBTN>
            <EARNBTN  className="header-button"><a className="title-main" href="https://difx.com/trade/CHLI_USDT">Buy CHILI</a></EARNBTN>
            <MenuGroup />
          </ButtonGroup>
        </Flex>
      </Container>

      

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >
        <img className='coming-soon' src='https://d1estwaobnn6ty.cloudfront.net/web/public/images/coming-soon2.png' />
        <img className='close-modal' src='https://d1estwaobnn6ty.cloudfront.net/web/public/images/x.png?3' onClick={closeModal} />
        {/*<button className='close-modal' onClick={closeModal}>X</button>*/}
      </Modal>
    </HeaderWrapper>
  )
}

export default Header;